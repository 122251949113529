import { useEffect, useRef, useState } from "react";
import {
  Link as RouterLink,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
} from "@mui/material";
// components
import MenuPopover from "../../components/MenuPopover";
//hooks
import { useAppContext } from "src/hooks";
import { baseUri, s3baseUrl } from "src/config/config";
import { Icon } from "@iconify/react";
import { useSockets } from "src/Context/Context-Sockets/ContextSockets";
import LogoutConfirmation from "src/components/LogoutConfirmation";
import { _user_logout } from "src/DAL/auth";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Profile",
    icon: "eva:person-fill",
    color: "#0acf97",
    path: "/profile",
  },
  {
    label: "Settings",
    icon: "eva:settings-2-fill",
    color: "#3da5f4",
    path: "/settings",
  },
  {
    label: "Change Password",
    icon: "wpf:password1",
    color: "#ff4979",
    path: "/new-password",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const { _get_user_profile } = useAppContext();
  const profile = _get_user_profile();
  const { pathname } = useLocation();
  const { socketOFF } = useSockets();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // console.log(profile, "j,sdnkfja");
  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleOpenLogoutModal = () => {
    setOpenLogoutModal(true);
  };

  // const handleLogout = () => {
  //   localStorage.clear();
  //   navigate("/login", { replace: true });
  //   socketOFF();
  // };

  const handleLogout = async () => {
    setIsLoading(true);
    const result = await _user_logout();
    if (result.code === 200) {
      localStorage.clear();
      navigate("/login", { replace: true });
      socketOFF();
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
  };
  useEffect(() => {
    setTimeout(() => {
      const is_path_includes = (path) => {
        return pathname.toString().includes(path);
      };

      if (!is_path_includes("/loan")) {
        localStorage.removeItem("store_filter_data");
      }

      if (!is_path_includes("/company-assets")) {
        localStorage.removeItem("store_page_data_for_company_assets");
      }
      if (
        !is_path_includes("/feedback") &&
        !is_path_includes("/feedback-progress-history") &&
        !is_path_includes("/feedback-history") &&
        !is_path_includes("/feedback-related")
      ) {
        localStorage.removeItem("store_page_data_for_feedbacks");
      }

      if (
        !is_path_includes("/feedback") &&
        !is_path_includes("/feedback-progress-history") &&
        !is_path_includes("/feedback-history") &&
        !is_path_includes("/feedback-related")
      ) {
        let data = {
          emp_name: "",
          date: "",
          search: "",
        };
        localStorage.setItem("FeedBackListSearch", JSON.stringify(data));
        localStorage.removeItem("FeedBackListSearch2");
      }

      if (
        !is_path_includes("/my-loan-request") &&
        !is_path_includes("/my-loan-request-details-loan/") &&
        !is_path_includes("/my-loan-request-Edit-loan/") &&
        !is_path_includes("/my-loan-request-add-loan")
      ) {
        localStorage.removeItem("store_filter_data_for_my_loan");
      }

      if (
        !is_path_includes("/payroll") &&
        !is_path_includes("/payroll/edit-pay/") &&
        !is_path_includes("/payroll/add-pay-empl/")
      ) {
        localStorage.removeItem("payroll_page");
        localStorage.removeItem("payroll_dates");
        localStorage.removeItem("payroll_search_text");
        localStorage.removeItem("payroll_rowsPerPage");
      }
      if (!is_path_includes("/my-attendance")) {
        localStorage.removeItem("my_attendance_page");
        localStorage.removeItem("my_attendance_dates");
        localStorage.removeItem("my_attendance_rowsPerPage");
      }
      if (
        !is_path_includes("/team-member") &&
        !is_path_includes("/team-member/edit-team-member/") &&
        !is_path_includes("/team-member/leaves-team-member/") &&
        !is_path_includes("/all-leaves-team-member/") &&
        !is_path_includes("/team-member/increment-team-member/")
      ) {
        localStorage.removeItem("team_members_tab");
        localStorage.removeItem("team_members_page");
        localStorage.removeItem("team_members_search_text");
        localStorage.removeItem("team_members_rowsPerPage");
      }
      if (
        !is_path_includes("/pending-admin-leave") &&
        !is_path_includes("/admin-leave/admin-edit-leave/") &&
        !is_path_includes("/admin-leave/admin-add-leave")
      ) {
        localStorage.removeItem("pending_admin_leave_page");
        localStorage.removeItem("pending_admin_leave_rowsPerPage");
        localStorage.removeItem("pending_admin_leave_inputs");
      }
      if (
        !is_path_includes("/approved-admin-leave") &&
        !is_path_includes("/admin-leave/admin-edit-leave/") &&
        !is_path_includes("/admin-leave/admin-add-leave")
      ) {
        localStorage.removeItem("admin_leave_page");
        localStorage.removeItem("admin_leave_rowsPerPage");
        localStorage.removeItem("admin_leave_inputs");
      }
      if (
        !is_path_includes("/leaves") &&
        !is_path_includes("/leaves/edit-leaves/") &&
        !is_path_includes("/leaves/add-leaves")
      ) {
        localStorage.removeItem("my_leave_page");
        localStorage.removeItem("my_leave_rowsPerPage");
        localStorage.removeItem("my_leave_inputs");
      }
      if (
        !is_path_includes("/my-team-member") &&
        !is_path_includes("/my-team-member/other-teams")
      ) {
        localStorage.removeItem("my_team_members_page");
        localStorage.removeItem("my_team_members_search_text");
        localStorage.removeItem("my_team_members_rowsPerPage");
      }

      if (
        !is_path_includes("/leave-maintenance-list") &&
        !is_path_includes("/leave-maintenance-list/leave-maintenance/")
      ) {
        localStorage.removeItem("leave_maintenance_list_page");
        localStorage.removeItem("leave_maintenance_list_inputs");
        localStorage.removeItem("leave_maintenance_list_search");
        localStorage.removeItem("leave_maintenance_list_rowsPerPage");
      }
      if (!is_path_includes("/leave-maintenance-list/leave-maintenance/")) {
        localStorage.removeItem("leave_maintenance_list_detail_page");
        localStorage.removeItem("leave_maintenance_list_detail_inputs");
        localStorage.removeItem("leave_maintenance_list_detail_rowsPerPage");
      }
      if (!is_path_includes("/lunch")) {
        localStorage.removeItem("lunch_page");
        localStorage.removeItem("lunch_inputs");
        localStorage.removeItem("lunch_rowsPerPage");
      }
      if (!is_path_includes("/team-member/all-leaves-team-member/")) {
        localStorage.removeItem("team_leave_inputs");
      }
      if (!is_path_includes("/fine") && !is_path_includes("/fine/add-fine")) {
        localStorage.removeItem("fine_page");
        localStorage.removeItem("fine_rowsPerPage");
        localStorage.removeItem("fine_search");
        localStorage.removeItem("fine_inputs");
      }
      if (
        !is_path_includes("/department") &&
        !is_path_includes("/department/add-department") &&
        !is_path_includes("/department/edit-department/")
      ) {
        localStorage.removeItem("department_page");
        localStorage.removeItem("department_rowsPerPage");
        localStorage.removeItem("department_search_text");
      }
      if (!is_path_includes("/companies")) {
        localStorage.removeItem("company_page");
        localStorage.removeItem("company_rowsPerPage");
        localStorage.removeItem("company_search_text");
      }

      if (
        !is_path_includes("/announcement") &&
        !is_path_includes("/announcement/add-announcement") &&
        !is_path_includes("/announcement/edit-announcement/")
      ) {
        localStorage.removeItem("announcement_page");
        localStorage.removeItem("announcement_rowsPerPage");
        localStorage.removeItem("announcement_search_text");
      }

      if (
        !is_path_includes("/improvement-feedback") &&
        !is_path_includes("/improvement-feedback/add-improvement") &&
        !is_path_includes("/improvement-feedback/edit-improvement/") &&
        !is_path_includes("/improvement-feedback/detail-improvement/")
      ) {
        localStorage.removeItem("feedback_improvement_page");
        localStorage.removeItem("feedback_improvement_rowsPerPage");
        localStorage.removeItem("feedback_improvement_search_text");
        localStorage.removeItem("feedback_inputs");
      }

      if (!is_path_includes("/upcoming-anniversary")) {
        localStorage.removeItem("anniversary_page");
        localStorage.removeItem("anniversary_rowsPerPage");
        localStorage.removeItem("anniversary_inputs");
      }
      if (!is_path_includes("/upcoming-birthday")) {
        localStorage.removeItem("birthday_page");
        localStorage.removeItem("birthday_rowsPerPage");
        localStorage.removeItem("birthday_inputs");
      }
      if (!is_path_includes("/upcoming-increment")) {
        localStorage.removeItem("upcoming_increment_page");
        localStorage.removeItem("upcoming_increment_rowsPerPage");
        localStorage.removeItem("upcoming_increment_inputs");
      }

      if (
        !is_path_includes("/top-up") &&
        !is_path_includes("/top-up/add-top-up") &&
        !is_path_includes("/top-up/edit-top-up/") &&
        !is_path_includes("/top-up/detail-top-up/")
      ) {
        localStorage.removeItem("top_up_page");
        localStorage.removeItem("top_up_rowsPerPage");
        localStorage.removeItem("top_up_inputs");
      }
      if (
        !is_path_includes("/feedbacks-templates") &&
        !is_path_includes("/feedbacks-templates/add-feedbacks-templates") &&
        !is_path_includes("/feedbacks-templates/edit-feedbacks-templates/") &&
        !is_path_includes("/feedbacks-templates/preview-feedbacks-templates/")
      ) {
        localStorage.removeItem("feedback_templates_page");
        localStorage.removeItem("feedback_templates_rowsPerPage");
        localStorage.removeItem("feedback_templates_inputs");
      }

      if (!is_path_includes("/roles")) {
        localStorage.removeItem("role_page");
        localStorage.removeItem("role_rowsPerPage");
        localStorage.removeItem("role_search_text");
      }

      if (
        !is_path_includes("/support-tickets") &&
        !is_path_includes("/admin-support-tickets/add-admin-support-tickets") &&
        !is_path_includes(
          "/admin-support-tickets/edit-admin-support-tickets/"
        ) &&
        !is_path_includes("/support_tickets/")
      ) {
        localStorage.removeItem("admin_support_tickets_page");
        localStorage.removeItem("admin_support_tickets_rowsPerPage");
        localStorage.removeItem("admin_support_tickets_search_text");
        localStorage.removeItem("admin_support_tickets_selected_tab");
      }
      if (
        !is_path_includes("/my-support-tickets") &&
        !is_path_includes("/my-support-tickets/add-my-support-tickets") &&
        !is_path_includes("/my-support-tickets/edit-my-support-tickets/") &&
        !is_path_includes("/my_support_tickets/")
      ) {
        localStorage.removeItem("my_support_tickets_page");
        localStorage.removeItem("my_support_tickets_rowsPerPage");
        localStorage.removeItem("my_support_tickets_search_text");
        localStorage.removeItem("my_support_tickets_selected_tab");
      }
      if (!is_path_includes("/tax-slabs")) {
        localStorage.removeItem("tax_slabs_page");
        localStorage.removeItem("tax_slabs_rowsPerPage");
      }
    }, 1000);
  }, [pathname]);
  return (
    <>
      <LogoutConfirmation
        openLogoutModal={openLogoutModal}
        setOpenLogoutModal={setOpenLogoutModal}
        handleLogout={handleLogout}
        isLoading={isLoading}
      />

      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {profile?.image ? (
          <>
            <Avatar
              sx={{ bgcolor: (theme) => theme.palette.primary.main }}
              src={s3baseUrl + profile?.image}
              alt={profile?.full_name?.charAt(0).toUpperCase()}
            />
          </>
        ) : (
          <>
            <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
              {profile?.full_name?.charAt(0).toUpperCase()}
            </Avatar>
          </>
        )}
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {profile?.full_name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {profile?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        {profile?.type == 1 && (
          <Stack sx={{ p: 1 }}>
            {MENU_OPTIONS.map((option) => (
              <MenuItem
                key={option.label}
                to={option.path}
                component={RouterLink}
                // onClick={() => handleNavigate(option.path)}
                sx={{
                  color: match(option.path)
                    ? (theme) => theme.palette.primary.main
                    : "black",
                  "&:hover": {
                    color: "#655fb1",
                  },
                  bgcolor: match(option.path)
                    ? (theme) =>
                        alpha(
                          theme.palette.primary.main,
                          theme.palette.action.selectedOpacity
                        )
                    : "",
                }}
                onClick={handleClose}
              >
                {option.icon && (
                  <Icon
                    fontSize="18"
                    style={{ color: option.color }}
                    className="me-2"
                    icon={option.icon}
                  />
                )}
                {option.label}
              </MenuItem>
            ))}
          </Stack>
        )}

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={handleOpenLogoutModal}
          sx={{
            m: 1,
            "&:hover": {
              color: "#655fb1",
            },
          }}
        >
          {
            <Icon
              fontSize="18"
              style={{ color: "#016699" }}
              className="me-2"
              icon="ri:logout-circle-line"
            />
          }
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
