import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { xs: 350, sm: 450 },
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "16px 24px",
  borderRadius: 1,
};

export default function LogoutConfirmation({
  openLogoutModal,
  setOpenLogoutModal,
  handleLogout,
  isLoading,
}) {
  const handleClose = () => {
    setOpenLogoutModal(false);
  };

  return (
    <div>
      <Modal
        disableTypography="true"
        open={openLogoutModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-theme">
          <Typography id="spring-modal-title" variant="h6" component="h2">
            Are you sure you want to logout?
          </Typography>

          {/* Buttons container */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 1,
            }}
          >
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{ marginRight: 2 }}
            >
              Cancel
            </Button>

            {/* <Button variant="contained" color="primary" onClick={handleLogout}>
              Agree
            </Button> */}
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleLogout}
              loading={isLoading} // Add a state for loading
            >
              Agree
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
